import React from "react"
import { graphql } from "gatsby"

import { QueryFragments } from "@querys/queryFragments" // eslint-disable-line

import { PageMeta } from "@components/pageMeta"
import Layout from "@components/layout"
import { Carousel, TextBlock, ImageText, Flavours } from "@blocks"
import { Footer } from '@nav/footer'

const IndexPage = ({ data: { page } }) => {
  const { meta, carousel, intro, imageText1, textBlock, flavours } = page || {}

  return (
    <Layout>
      <PageMeta {...meta} />
      <Carousel images={carousel} />
      <TextBlock text={intro} skinny={true} />
      <ImageText id="stockists" text={imageText1.text} image={imageText1.image} />
      <Flavours flavours={flavours} />
      <TextBlock text={textBlock} />
      <Footer />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      meta {
        ...MetaCardFields
      }
      carousel{
        ...ImageWithPreview
        asset{
          url
        }
      }
      imageText1{
        text:_rawText(resolveReferences: {maxDepth: 100})
        image{
          ...ImageWithPreview
        }

      }
      intro:_rawText1(resolveReferences: {maxDepth: 100})
      textBlock:_rawText2(resolveReferences: {maxDepth: 100})
      flavours {
        copy {
          image{
            ...ImageWithPreview
          }
          text:_rawText(resolveReferences: {maxDepth: 100})
        }
        name
        url
        nutritionPopup {
          details
          copy
          heading
          table{
            ...ImageWithPreview
          }
        }
        storyPopup {
          storyCopy:_rawCopy(resolveReferences: {maxDepth: 100})
        }
      }
    }
  }
`

import React from 'react'
import Image from 'gatsby-plugin-sanity-image'
import { Link } from 'gatsby'
import useEmblaCarousel from 'embla-carousel-react';

import { useSiteConfig } from '@querys/useSiteConfig'
import { PortableTextBlock } from '@modules/portableTextBlock'

export const Footer = () => {
    const { footer } = useSiteConfig()
    const { heading, handle, hashtag, images, copy } = footer || {}

    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: false,
        dragFree: true,
        containScroll: true,
        startIndex: 0,
    });

    return (
        <div className="grid grid-cols-14 mt-28 lg:mt-48 mb-16 lg:mb-28 overflow-hidden">
            <div className="col-start-2 col-end-14 flex flex-col lg:flex-row lg:items-end justify-between">
                <span className="text-6xl leading-6xl lg:text-9xl lg:leading-9xl font-extrabold text-center sm:text-left">{heading}</span>
                <div className="flex justify-around lg:justify-between lg:gap-x-12 ig-tags font-extrabold mb-5">
                    <a href={handle.link} target="_blank" rel="noreferrer">{handle.linkText}</a>
                    <a href={hashtag.link} target="_blank" rel="noreferrer">{hashtag.linkText}</a>
                </div>
            </div>

            {/* Desktop Images */}
            <div className="hidden lg:grid col-start-2 col-end-14 grid-cols-2 lg:grid-cols-4 gap-x-2 lg:mt-8">
                {images.map((ig, i) => {
                    return (
                        <a href={ig.url} target="_blank" rel="noreferrer" className="w-full overflow-hidden"><Image className="transform hover:scale-105 transition duration-300 ease-in-out" {...ig.image} key={i} /></a>
                    )
                })}
            </div>

            {/* Mobile Images */}
            <div ref={emblaRef} className="lg:hidden col-start-2 col-end-14 w-full my-12 lg:mt-12 lg:mb-4 relative">
                <div className="flex w-full h-full gap-x-2">
                    {images.map((ig, i) => {
                        return (
                            <div key={i} className="w-full" style={{ flex: '0 0 auto' }}>
                                <a href={ig.url} target="_blank" rel="noreferrer" className="w-full overflow-hidden"><Image {...ig.image} className="w-full mx-auto" /></a>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="col-start-2 col-end-14 flex flex-col lg:flex-row lg:items-end justify-between mt-6 lg:mt-12 lg:mt-20 w-full text-center lg:text-left">
                <PortableTextBlock className="prose md:prose-lg" text={copy} />
                <a href="https://cdn.sanity.io/files/e2329nam/production/b9dba31e367be79139f9ddec02d76ae84f7ec1a6.pdf" target="_blank" rel="noreferrer" className="underline text-base leading-base">Liquor License</a>
            </div>
        </div>
    )
}

import React from "react"
import Image from "gatsby-plugin-sanity-image"

import { BlockWrapper } from "../blockWrapper"
import { PortableTextBlock } from "@modules/portableTextBlock"

const ImageText = ({ id, text, image }) => {
  return (
    <div id={id} className="grid grid-cols-14 mt-28 relative mb-40 gap-y-8 lg:gap-y-16">
      <div className="col-start-2 col-end-14 lg:col-end-8 self-center prose md:prose-lg lg:ml-12 relative z-20">
        <PortableTextBlock text={text} />
      </div>
      <div className="row-start-1 col-start-2 col-end-14 lg:col-start-8 relative z-10">
        {image && <Image className="w-full" {...image} />}
      </div>
      <div className="floor h-32 lg:h-60 w-full col-start-1 col-end-15 absolute bottom-1/2 lg:-bottom-20" />
    </div>
  )
}

export default ImageText

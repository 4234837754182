import React from 'react'
import Image from 'gatsby-plugin-sanity-image'
import useEmblaCarousel from 'embla-carousel-react';

const Carousel = ({ images }) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: true,
        dragFree: true,
        containScroll: false,
        startIndex: 1,
    });

    return (
        <div className="w-full overflow-hidden">
            <div ref={emblaRef} className="w-full my-12 md:mt-12 md:mb-4 relative">
                <div className="flex w-full h-full">
                    {images.map((image, i) => {
                        return (
                            <div key={i} className="relative can w-full mr-4 md:mr-28" style={{ flex: '0 0 auto' }}>
                                <Image {...image} className="w-full mx-auto" alt="liaise vodka & soda" />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Carousel
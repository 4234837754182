import React from "react"

import { PortableTextBlock } from "@modules/portableTextBlock"

const TextBlock = ({ text, skinny }) => {
  return (
    <div className={`prose md:prose-lg max-w-none grid grid-cols-14 text-center`}>
      <PortableTextBlock text={text} className={`col-start-2 col-end-14 ${skinny ? 'md:col-start-4 md:col-end-12' : 'md:col-start-3 md:col-end-13'}`} />
    </div>
  )
}

export default TextBlock

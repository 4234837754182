import React, { useState } from 'react'
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion'
import Image from 'gatsby-plugin-sanity-image'

import { PortableTextBlock } from "@modules/portableTextBlock"

const tabVars = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            duration: .3,
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: .3,
        }
    }
}

const Tabs = ({ flavour }) => {
    const { image, text } = flavour.copy || {}

    return (
        <>
            <motion.div variants={tabVars} initial="initial" animate="animate" className="col-start-2 col-end-14 lg:col-start-3 lg:col-end-7">
                <Image className="w-2/3 mx-auto lg:w-full" {...image} />
            </motion.div>

            <motion.div variants={tabVars} initial="initial" animate="animate" className="lg:-mt-4 lg:mt-0 col-start-2 col-end-14 lg:col-start-8 lg:col-end-13 prose md:prose-lg flex items-center mb-24 lg:mb-0">
                <PortableTextBlock text={text} flavourLink={flavour.url} nutritionPopup={flavour.nutritionPopup} storyPopup={flavour.storyPopup} />
            </motion.div>
        </>
    )
}

const Flavours = ({ flavours }) => {
    const [activeTab, setActiveTab] = useState(0)
    return (
        <>
            {/* Desktop */}
            <div className="flavours hidden lg:grid grid-cols-14 mb-12 lg:mb-24">
                <div className="flex flex-col lg:flex-row items-center justify-center col-start-2 col-end-14 lg:col-start-3 lg:col-end-13 mb-20 gap-x-10">
                    <AnimateSharedLayout>
                        {flavours.map((tab, i) => (
                            <div key={i} className="relative">
                                <button onClick={() => setActiveTab(i)} className="p-4 focus:outline-none text-3xl leading-3xl tracking-liaise">{tab.name}</button>
                                {activeTab === i && <motion.div layoutId='line' className="w-full h-1 bg-liaise-yellow absolute bottom-0" />}
                            </div>
                        ))}
                    </AnimateSharedLayout>
                </div>
                <AnimatePresence>
                    <Tabs key={activeTab} flavour={flavours[activeTab]} />
                </AnimatePresence>
            </div>

            {/* Mobile */}
            <div className="flavours lg:hidden grid grid-cols-14">
                <div className="flex flex-col lg:flex-row items-center justify-center col-start-2 col-end-14 gap-x-5">
                    {flavours.map((tab, i) => (
                        <Tabs key={i} flavour={tab} />
                    ))}
                </div>
            </div>
        </>
    )
}

export default Flavours
